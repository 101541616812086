<template>
  <v-card
    flat
  >
    <v-card-title>
      {{ $vuetify.lang.t('$vuetify.healthReport.heartRate') }}
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        text
        @click="deleteDialog = true"
      >
        {{ $vuetify.lang.t('$vuetify.common.detailDatas') }}
      </v-btn>
    </v-card-title>

    <v-card-text
      class="text-center justify-center"
    >
      <v-row>
        <v-col
          cols="6"
        >
          <h1
            class="font-weight-bold display-1 basil--text"
          >
            <span
              v-if="heartRate && heartRate.maxValue"
            >
              {{ heartRate.maxValue }}
            </span>
            <span
              v-else
            >
              -
            </span>
          </h1>
        </v-col>
        <v-col
          cols="6"
        >
          <h1
            class="font-weight-bold display-1 basil--text"
          >
            <span
              v-if="heartRate && heartRate.minValue"
            >
              {{ heartRate.minValue }}
            </span>
            <span
              v-else
            >
              -
            </span>
          </h1>
        </v-col>
        <v-col
          cols="6"
        >
          <h1 class="font-weight-bold body-2 basil--text">
           {{ $vuetify.lang.t('$vuetify.common.highest') }} {{ $vuetify.lang.t('$vuetify.healthReport.heartRate') }}
          </h1>
        </v-col>
        <v-col
          cols="6"
        >
          <h1 class="font-weight-bold body-2 basil--text">
            {{ $vuetify.lang.t('$vuetify.common.lowest') }} {{ $vuetify.lang.t('$vuetify.healthReport.heartRate') }}
          </h1>
        </v-col>
      </v-row>
    </v-card-text>

    <v-responsive :aspect-ratio="16/9">
      <v-chart
        v-if="option"
        :options="option"
      />
    </v-responsive>

    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="800"
    >
      <day-data
        v-if="deleteDialog"
        :date-range="dateRange"
        :patient-id="patientId"
        data-type="heartRate"
        @close="deleteDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import 'echarts/lib/chart/line'

  export default {
    components: {
      DayData: () => import('./DayData')
    },

    props: {

      dateRange: {
        type: Array,
        default: () => []
      },
      patientId: {
        type: String,
        default: () => null
      }
    },

    data () {
      return {
        deleteDialog: false,
        option: null,
        dateDiff: 0,
        heartRate: null
      }
    },


    watch: {
      dateRange: {
         handler: function() {
            this.initialize()
         }
      },

      patientId: {
         handler: function() {
            this.initialize()
         }
      }
    },

    computed: {
      ...mapGetters({
        currentHeartRate: 'healthData/currentHeartRate'
      })
    },

    created () {
      this.initialize()

    },

    methods: {
      ...mapActions({
        findHeartRateTrendDataByPatientIdAndRangeDate: 'healthData/findHeartRateTrendDataByPatientIdAndRangeDate',
        findHeartRateTrendDataByPatientIdAndTargetDate: 'healthData/findHeartRateTrendDataByPatientIdAndTargetDate'
      }),

      initialize() {
        let dateFrom = this.$moment(this.dateRange[0])
        let dateTo = this.$moment(this.dateRange[1])
        this.dateDiff = dateTo.diff(dateFrom, 'days') + 1
        if (this.dateDiff === 1) {
          this.findHeartRateTrendDataByPatientIdAndTargetDate({ patientId: this.patientId, targetDate: this.dateRange[0]}).then( () => {
            this.setDatas()
          })
        } else {
          this.findHeartRateTrendDataByPatientIdAndRangeDate({ params: { patientId: this.patientId }, data: { dateFrom: this.dateRange[0], dateTo: this.dateRange[1] } }).then( () => {
            this.setDatas()
          })
        }
      },

      setDatas () {
        this.heartRate = this.currentHeartRate
        let dateFrom = this.$moment(this.dateRange[0])

        let timeData= null, heartRateData = null

        if (this.dateDiff === 1) {
          timeData = Array(12).fill("").map( (_, i) => {
            let hour = i * 2
            return `${ hour <= 9 ? '0' + hour : hour }:00`
          })
          heartRateData = Array(12).fill(0)
        } else {
          timeData = Array(this.dateDiff).fill("").map( (_, i) => {
            let currDate = dateFrom.add(i === 0 ? 0 : 1, 'days')
            return currDate.format('YYYY-MM-DD')
          })
          heartRateData = Array(this.dateDiff).fill(0)
        }

        if (this.currentHeartRate && this.currentHeartRate.data) {
          this.currentHeartRate.data.forEach( element => {
            let index = timeData.findIndex( ele =>  this.dateDiff === 1 ? (element.key.startsWith(ele)) : (ele === element.time) )
            if (index !== -1 ) {
             heartRateData[index] = element.value
            }
          })
        }

        this.option = Object.assign({}, {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: timeData
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: this.$vuetify.lang.t('$vuetify.healthReport.heartRate'),
              type: 'line',
              data: heartRateData
            }
          ]
        })
      }
    }
  }
</script>

<style>
.echarts {
  width: 100%;
  height: 100%;
}
</style>
